// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// AMBIENTE DEV desarrollo
// ng serve --configuration=dev
// ng build --configuration=dev
export const environment = {
  production: false,
  // para mi fiel /////
  Ambiente: 'trinitasTestAU',
  APP_ID: '',
  APP_SECRET: '',
  URL_MIFIEL: '',
  // para mi fiel /////
  URL_SERVICIOS: 'https://tritest.devfactorgfc.com/api/v1',
 // SECRET_KEY: '71e141d3a016ffd6bd94558a5bb80b15',
  SECRET_KEY: 'f46cc9d555ede2e4918c03df4e4d2624',
  CLIENTE: 'FACTORGFCGLOBAL',

  firebase: {
    apiKey: "AIzaSyBHku5eOByGWxNwqsq_pBQ7m2DeinCfHDY",
    authDomain: "trinitastestau.firebaseapp.com",
    projectId: "trinitastestau",
    storageBucket: "trinitastestau.appspot.com",
    messagingSenderId: "4639192759",
    appId: "1:4639192759:web:f6a3f9ef4e6bb4d9513f32",
    measurementId: "G-FB8WSR6KTV"
  },
  
  AWS: {
    accessKeyId: "AKIAWRCSQFXTXKQ57W5G",
    secretAccessKey: "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",
    region: "us-east-1",
    folder: "factoraje_contributor_documents/Trintest/",
    bucketNomina: "elasticbeanstalk-us-east-1-448997109223",
  },

};
